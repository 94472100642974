import './list.scss';
import dataApi from '@/api/system';

export default {
  name: 'project-list',
  components: {},
  data() {
    return {
      dataApi,
      page: {
        pageSize: 10,
        // pagerCount: 5,
        pageSizes: [3, 10, 20, 30, 40, 50, 100]
      },
      searchData: {},
      tableData: [],
      id: 'resource_id',
      selectionDataList: [],
      selectionIdList: [],
      tbleOption: {
        // headerAlign: 'center',
        // defaultExpandAll: true,
        // // defaultExpandAll: true,
        // lazy: true,
        rowKey: 'resource_id',
        align: 'center',
        ...this.$store.getters.tableConfig,
        column: [
          {
            // 角色ID   role_id
            label: 'ID',
            addDisplay: false,
            editDisplay: false,
            hide: true,
            prop: 'resource_id'
          },
          { // 片段名称   name
            filterText: '搜索关键字制自定义',
            // multiple: true,
            props: {
              label: 'name',
              value: 'resource_id'
            },
            // dicUrl: 'System/Resource/getList',
            searchRange: true,
            search: true,
            span: 24,
            label: '父级资源',
            prop: 'parent_id',
            // filter: false,
            type: 'tree',
            hide: true,
            nodeClick: (data) => {
              console.log(data.url);
              const url = this.findObject(this.tbleOption.column, 'url');
              url.value = data.url;
              console.log('data.url', url);
            },
            dicData: this.tableData
          },
          { // 片段名称   name
            searchRange: true,
            search: true,
            label: '资源名称',
            span: 24,
            rules: [
              {
                required: true,
                message: '请输入资源名称',
                trigger: 'blur'
              }
            ],
            prop: 'name'
          },
          { // 片段名称   name
            label: '资源路径',
            span: 24,
            value: '/',
            searchRange: true,
            search: true,
            rules: [
              {
                required: true,
                message: '请输入资源路径',
                trigger: 'blur'
              }
            ],
            prop: 'url'
          },
          {
            label: '请求类型',
            span: 12,
            type: 'select',
            searchRange: true,
            search: true,
            value: 'GET',
            dicData: [
              {
                label: 'GET',
                value: 'GET'
              },
              {
                label: 'DELETE',
                value: 'DELETE'
              },
              {
                label: 'POST',
                value: 'POST'
              },
              {
                label: 'PUT',
                value: 'PUT'
              }
            ],
            rules: [
              {
                required: true,
                message: '请求类型',
                trigger: 'blur'
              }
            ],
            prop: 'method'
          },

          {
            // 资源状态 0停用1启用 status
            label: '资源状态',
            // span: 12,
            searchRange: true,
            search: true,
            type: 'radio',
            value: 1,
            dicData: [
              {
                label: '停用',
                value: 0
              },
              {
                label: '启用',
                value: 1
              }
            ],
            rules: [
              {
                required: true,
                message: '请选择资源状态',
                trigger: 'blur'
              }
            ],
            prop: 'status'
          },
          // {
          //   label: '额外参数',
          //   prop: 'is_down',
          //   type: 'dynamic',
          //   span: 24,
          //   children: {
          //     align: 'center',
          //     type: 'form',
          //     headerAlign: 'center',
          //     index: false,
          //     rowAdd: (done) => {
          //       // this.$message.success('新增回调');
          //       // console.log(done);
          //       done();
          //       // done({
          //       //   input: '默认值'
          //       // });
          //     },
          //     rowDel: (row, done) => {
          //       console.log(row);
          //       // this.$message.success('删除回调');
          //       done();
          //     },
          //     column: [{
          //       label: '索引',
          //       span: 8,
          //       prop: 'key'
          //       // formslot: true
          //     },
          //     {
          //       label: '值',
          //       // formslot: true,
          //       span: 16,
          //       prop: 'value'
          //     }]
          //   }
          // },
          // { // 片段名称   name
          //   label: '下载',
          //   value: false,
          //   span: 24,
          //   type: 'switch',
          //   prop: 'is_down'
          // },
          {
            // 资源状态 0停用1启用 status
            label: '下载状态',
            span: 12,
            searchRange: true,
            search: true,
            type: 'radio',
            value: 0,
            dicData: [
              {
                label: '默认',
                value: 0
              },
              {
                label: '下载',
                value: 1
              }
            ],
            rules: [
              {
                required: true,
                message: '请选择资源状态',
                trigger: 'blur'
              }
            ],
            prop: 'is_down'
          },
          { // 片段名称   name
            label: '说明',
            span: 24,
            type: 'textarea',
            prop: 'remark'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ]
      }
    };
  },
  computed: {},
  created() {
  },
  mounted() {
    // this.getList();
  },
  filters: {},
  methods: {
    // 多选
    selectionChange(list) {
      console.log('多选', list);
      this.selectionDataList = list;
      this.selectionIdList = [];
      for (let i = 0; i < list.length; i++) {
        this.selectionIdList.push(list[i][this.id]);
      }
    },
    // 返回
    goBack() {
      this.$router.go(-1);
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit(form, done, loading) {
      // console.log('form', form, loading);
      // dict_type: this.$route.params.id,
      form.submitParameter = JSON.stringify(form.submitParameter);
      setTimeout(() => {
        done();
      }, 1000);
      const postData = { ...form, dict_type: this.$route.params.id };
      console.log('postData', postData);
      dataApi.add.r(postData).then((res) => {
        console.log(res);
        done(form);
        loading();
        this.getList();
      });
      // done(form);
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form[this.id] }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate(form, index, done, loading) {
      setTimeout(() => {
        done();
      }, 1000);
      console.log('index', form, index);
      dataApi.update.r(form).then((res) => {
        console.log(res);
        done(form);
        loading();
      });
      // done(form);
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      console.log('接口参数', postData);
      dataApi.getList.r(postData).then((res) => {
        this.tableData = res.data;
        this.tbleOption.column[1].dicData = res.data;
      });
    }
  }
};
