import http from '@/utils/http';

/**
 *  代码片段
 */
export default {
  getList: {
    p: 'post,System/Resource/getList',
    r: () => http({ url: 'System/Resource/getList', method: 'post' })
  },
  getOne: {
    p: 'post,System/Resource/getone',
    r: (data) => http({ url: 'System/Resource/getone', method: 'post', data })
  },
  add: {
    p: 'post,System/Resource/add',
    r: (data) => http({ url: 'System/Resource/add', method: 'post', data })
  },
  update: {
    p: 'post,System/Resource/update',
    r: (data) => http({ url: 'System/Resource/update', method: 'post', data })
  },
  del: {
    p: 'post,System/Resource/delete',
    r: (data) => http({ url: 'System/Resource/delete', method: 'post', data })
  },
  confPost: {
    p: 'post,/system/config/edit',
    r: (data) => http({ url: '/system/config/edit', method: 'post', data })
  },
  confInfo: {
    p: 'post,/system/config/info',
    r: (data) => http({ url: '/system/config/info', method: 'post', data })
  }
};
