<template>
  <div class="gcharts" :id="id"></div>
</template>

<script>
import { Chart } from '@antv/g2';

export default {
  name: 'LineChartsVue',
  data() {
    return {
      chart: {}
    };
  },
  props: {
    charData: {
      type: Array,
      default() {
        return {
          data: []
        };
      }
    },
    id: {
      type: String,
      default() {
        return 'line';
      }
    }
  },
  // 如果使用serverData传过来的静态数据 请使用mounted()方法 并注释掉watch
  mounted() {
    this.drawChart();
  },
  // 监听API接口传过来的数据 使用watch
  watch: {
    charData(val, oldVal) {
      // 监听charData，当发生变化时，触发这个回调函数绘制图表
      console.log('new: %s, old: %s', val, oldVal);
      this.drawChart(val);
    }
  },
  methods: {
    drawChart() {
      this.chart = new Chart({
        container: this.id,
        autoFit: true,
        height: 300,
        appendPadding: 30,
        renderer: 'svg'
      });
      this.chart.data(this.charData);
      this.chart.scale({
        month: {
          range: [0, 1]
        },
        temperature: {
          nice: true
        }
      });

      this.chart.tooltip({
        showCrosshairs: true,
        shared: true
      });

      this.chart.axis('temperature', {
        label: {
          formatter: (val) => `${val} °C`
        }
      });
      this.chart
        .line()
        .position('month*temperature')
        .color('city')
        .shape('smooth');

      this.chart
        .point()
        .position('month*temperature')
        .color('city')
        .shape('circle');
      this.chart.render();
    }
  }
};
</script>

<style lang="scss" scoped>
.gcharts{
  width:100%;
  height:100%;
}
</style>
