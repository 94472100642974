import './panel.scss';
import CountTo from 'vue-count-to';
import LineCharts from '@/components/Charts/lineCharts';
import BarCharts from '@/components/Charts/barCharts';
import { RightPanel } from '@/layout/components';
// import { getUserInfo } from '@/utils/auth';

export default {
  name: 'index',
  components: {
    CountTo,
    RightPanel,
    LineCharts,
    BarCharts
  },
  data() {
    return {
      lineData: [
        { month: 'Jan', city: 'Tokyo', temperature: 7 },
        { month: 'Jan', city: 'London', temperature: 3.9 },
        { month: 'Feb', city: 'Tokyo', temperature: 6.9 },
        { month: 'Feb', city: 'London', temperature: 4.2 },
        { month: 'Mar', city: 'Tokyo', temperature: 9.5 },
        { month: 'Mar', city: 'London', temperature: 5.7 },
        { month: 'Apr', city: 'Tokyo', temperature: 14.5 },
        { month: 'Apr', city: 'London', temperature: 8.5 },
        { month: 'May', city: 'Tokyo', temperature: 18.4 },
        { month: 'May', city: 'London', temperature: 11.9 },
        { month: 'Jun', city: 'Tokyo', temperature: 21.5 },
        { month: 'Jun', city: 'London', temperature: 15.2 },
        { month: 'Jul', city: 'Tokyo', temperature: 25.2 },
        { month: 'Jul', city: 'London', temperature: 17 },
        { month: 'Aug', city: 'Tokyo', temperature: 26.5 },
        { month: 'Aug', city: 'London', temperature: 16.6 },
        { month: 'Sep', city: 'Tokyo', temperature: 23.3 },
        { month: 'Sep', city: 'London', temperature: 14.2 },
        { month: 'Oct', city: 'Tokyo', temperature: 18.3 },
        { month: 'Oct', city: 'London', temperature: 10.3 },
        { month: 'Nov', city: 'Tokyo', temperature: 13.9 },
        { month: 'Nov', city: 'London', temperature: 6.6 },
        { month: 'Dec', city: 'Tokyo', temperature: 9.6 },
        { month: 'Dec', city: 'London', temperature: 4.8 }],
      barData: [
        { country: '巴西', population: 18203 },
        { country: '印尼', population: 23489 },
        { country: '美国', population: 29034 },
        { country: '印度', population: 104970 },
        { country: '中国', population: 131744 }
      ],
      amount: [
        {
          list: [
            {
              title: '交易笔数',
              toDay: 1586,
              state: 2, // 1 涨 2 降
              yesterday: 1851
            },
            {
              title: '放款金额(元)',
              toDay: 11678.24,
              state: 2, // 1 涨 2 降
              yesterday: 45678.52
            },
            {
              title: '佣金金额(元)',
              toDay: 834423,
              state: 2, // 1 涨 2 降
              yesterday: 1453101
            },
            {
              title: '提现金额',
              toDay: 54126,
              state: 2, // 1 涨 2 降
              yesterday: 332521
            }
          ]
        },
        {
          list: [
            {
              title: '成交客户数',
              toDay: 640,
              state: 2, // 1 涨 2 降
              yesterday: 985
            },
            {
              title: '新增会员数',
              toDay: 547,
              state: 2, // 1 涨 2 降
              yesterday: 324
            },
            {
              title: '文章阅读量',
              toDay: 75.54,
              state: 1, // 1 涨 2 降
              yesterday: 60.44
            }
          ]
        }
      ]
    };
  },
  created() {
    // this.userInfo = this.$store.getters.userInfo;
    // console.log('a', this.userInfo);
  }
};
